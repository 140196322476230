import React from "react";
import { Link } from "react-router-dom";
import {
    Box,
    Flex,
    HStack,
    IconButton,
    Button,
    useDisclosure,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerContent,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import logoWhite from "../assets/logo-white.svg";

const Navbar = () => {
    // Opening Mobile Nav Drawer
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Box bg={"#1B4748"} px={4}>
            <Flex h={20} alignItems="center" justifyContent="space-between">
                <Link to="/">
                    <img src={logoWhite} alt="BetaPersona Logo" />
                </Link>

                <HStack
                    display={{ base: "none", lg: "flex" }}
                    spacing={2}
                    alignItems="center"
                    direction="row"
                >
                    <HStack
                        as="nav"
                        spacing={1}
                        display={{ base: "none", lg: "flex" }}
                    >
                        <Link to="/Managers">
                            <Button
                                variant="link"
                                fontSize={{ lg: "14px", xl: "18px" }}
                                color="white"
                                pr={6}
                                m={0}
                            >
                                For hiring managers
                            </Button>
                        </Link>
                        <Link to="/HRBP">
                            <Button
                                variant="link"
                                fontSize={{ lg: "14px", xl: "18px" }}
                                color="white"
                                pr={6}
                                m={0}
                            >
                                For HRBP's
                            </Button>
                        </Link>
                        <Link to="/Recruiters">
                            <Button
                                variant="link"
                                fontSize={{ lg: "14px", xl: "18px" }}
                                color="white"
                                pr={6}
                                m={0}
                            >
                                For recruiters
                            </Button>
                        </Link>
                        <Link to="/Professionals">
                            <Button
                                variant="link"
                                fontSize={{ lg: "14px", xl: "18px" }}
                                color="white"
                                pr={6}
                                m={0}
                            >
                                For registered professionals
                            </Button>
                        </Link>
                    </HStack>
                    <Button
                        onClick={() =>
                            window.open(
                                "https://reportengine.pages.dev/",
                                "_blank"
                            )
                        }
                        bg={"rgba(244, 244, 244, 0.12)"}
                        _hover={{ bg: "rgba(244, 244, 244, 0.30)" }}
                        color="white"
                        m={0}
                    >
                        Login/Register
                    </Button>
                </HStack>

                <IconButton
                    size="md"
                    icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                    aria-label="Open Menu"
                    display={{ lg: "none" }}
                    onClick={onOpen}
                />

                {/* Mobile Nav Drawer */}
                <Drawer placement="right" isOpen={isOpen} onClose={onClose}>
                    <DrawerContent>
                        <DrawerHeader
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Button
                                onClick={() =>
                                    window.open(
                                        "https://0eb6e24a.reportengine.pages.dev/",
                                        "_blank"
                                    )
                                }
                                bg={"#296968"}
                                color="white"
                                fontWeight="bold"
                                m={0}
                            >
                                Login/Register
                            </Button>
                            <Button onClick={onClose}>X</Button>
                        </DrawerHeader>
                        <DrawerBody
                            pb="18em"
                            display="flex"
                            flexDir="column"
                            justifyContent="space-between"
                        >
                            <Link to="/Managers">
                                <Button
                                    variant="link"
                                    fontSize={{ base: "20px", md: "24px" }}
                                    color="#363636"
                                    pr={6}
                                    m={0}
                                >
                                    For hiring managers
                                </Button>
                            </Link>
                            <Link to="/HRBP">
                                <Button
                                    variant="link"
                                    fontSize={{ base: "20px", md: "24px" }}
                                    color="#363636"
                                    pr={6}
                                    m={0}
                                >
                                    For HRBP's
                                </Button>
                            </Link>
                            <Link to="/Recruiters">
                                <Button
                                    variant="link"
                                    fontSize={{ base: "20px", md: "24px" }}
                                    color="#363636"
                                    pr={6}
                                    m={0}
                                >
                                    For recruiters
                                </Button>
                            </Link>
                            <Link to="/Professionals">
                                <Button
                                    variant="link"
                                    fontSize={{ base: "20px", md: "24px" }}
                                    color="#363636"
                                    pr={6}
                                    m={0}
                                >
                                    For registered professionals
                                </Button>
                            </Link>
                            <Link to="/">
                                <Button
                                    variant="link"
                                    fontSize={{ base: "20px", md: "24px" }}
                                    color="#363636"
                                    pr={6}
                                    m={0}
                                    display="flex"
                                >
                                    <svg
                                        id="drawerHomeIcon"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="1.2em"
                                        height="1.2em"
                                        viewBox="0 0 24 24"
                                    >
                                        <g
                                            fill="none"
                                            stroke="#363636"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2.3"
                                        >
                                            <path d="M15 21v-8a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v8" />
                                            <path d="M3 10a2 2 0 0 1 .709-1.528l7-5.999a2 2 0 0 1 2.582 0l7 5.999A2 2 0 0 1 21 10v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                                        </g>
                                    </svg>
                                    <h4>Home</h4>
                                </Button>
                            </Link>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            </Flex>

            {/* {isOpen ? (
                <Box pb={4} display={{ lg: "none" }}>
                    <Stack as="nav" spacing={4}>
                        <MenuItem>Home</MenuItem>
                        <MenuItem>About</MenuItem>
                        <MenuItem>Services</MenuItem>
                        <MenuItem>Contact</MenuItem>
                        <Button colorScheme="teal" variant="outline">
                            Sign In
                        </Button>
                    </Stack>
                </Box>
            ) : null} */}
        </Box>
    );
};

export default Navbar;
