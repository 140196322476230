import React from "react";
import { Heading, Text, Box, Button, Link } from "@chakra-ui/react";

export default function PageHeading({ heading, subText }) {
    return (
        <Box m={{ base: "3em 3em 10em 3em", lg: "7em" }}>
            <Heading>{heading}</Heading>
            <Text>{subText}</Text>
            <Link
                href="https://calendly.com/lawriefraser/30min"
                target="_blank"
            >
                {" "}
                <Button
                    bg={"#F8641E"}
                    shadow="1px 2px 5px 1px rgba(0, 0, 0, 0.25)"
                    color="white"
                    m={{ base: "0", lg: "0.5em 0 3em 0" }}
                    _hover={{ bg: "rgba(248, 100, 30, 0.7)" }}
                >
                    Book an appointment
                </Button>
            </Link>
        </Box>
    );
}
