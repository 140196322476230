import React from "react";
import { Heading, Text, Flex, Box, Image } from "@chakra-ui/react";

export default function TextImage({
    heading,
    bodyText,
    bodyTextPadding,
    bodyTextMaxHeight,
    imgSrc,
    altText,
    imgDesktopWidth,
    imgPositionChangeOnMobile,
    overflow,
}) {
    return (
        <Flex
            direction={{ base: "column", lg: "row" }}
            w="100vw"
            p={{ base: "0", lg: "4em" }}
            justify="center"
            align="center"
        >
            {imgPositionChangeOnMobile && (
                <Image
                    src={imgSrc}
                    alt={altText}
                    style={{ objectFit: "contain" }}
                    display={{ base: "block", lg: "none" }}
                />
            )}

            <Box
                maxHeight={bodyTextMaxHeight}
                w={{ base: "100%", lg: "43%" }}
                // h={{ base: "100%", lg: "52vh" }}
                p={bodyTextPadding}
                mb={{ base: "1.5em", lg: "0" }}
                overflow={overflow}
            >
                <Heading as="h3" size="lg">
                    {heading}
                </Heading>

                {bodyText.map((text) => {
                    return <Text fontSize="md">{text}</Text>;
                })}
            </Box>
            {!imgPositionChangeOnMobile && (
                <Image
                    src={imgSrc}
                    alt={altText}
                    style={{ objectFit: "contain" }}
                    display={{ base: "block", lg: "none" }}
                />
            )}

            <Image
                w={imgDesktopWidth}
                src={imgSrc}
                alt={altText}
                style={{ objectFit: "contain" }}
                display={{ base: "none", lg: "block" }}
            />
        </Flex>
    );
}
