import React from "react";
import PageHeading from "../components/PageHeading";
import Block from "../components/Block";
import TextImage from "../components/TextImage";
import TextImageGrid from "../components/TextImageGrid";
import CaseStudy from "../components/CaseStudy";
import PointBubbles from "../components/PointBubbles";
import { Image, Box, Divider } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";
import ladyLaptop from "../assets/lady-laptop.webp";
import womenInMeeting from "../assets/women-in-meeting.webp";
import womanBurnout from "../assets/woman-burnout.webp";
import hrActivitiesImgMobile from "../assets/HR-Activities-mobile.svg";
import hrActivitiesImg from "../assets/HR-Activities.svg";
import airportImg from "../assets/airport.webp";
import yourAmbitHeadingMobile from "../assets/Your-Ambit-heading-mobile.svg";
import yourToolsHeadingMobile from "../assets/Your-Tools-heading-mobile.svg";
import yourChallengeHeadingMobile from "../assets/Your-Challenge-heading-mobile.svg";
import yourSolutionHeadingMobile from "../assets/Your-Solution-heading-mobile.svg";
import yourAmbitHeading from "../assets/Your-Ambit-heading.svg";
import yourToolsHeading from "../assets/Your-Tools-heading.svg";
import yourChallengeHeading from "../assets/Your-Challenge-heading.svg";
import yourSolutionHeading from "../assets/Your-Solution-heading.svg";
import whiteFade from "../assets/white-fade.svg";
import managersPointBubblesMobile from "../assets/managers-point-bubbles-mobile.svg";
import managersPointBubbles from "../assets/managers-point-bubbles.svg";

export default function Managers() {
    return (
        <div>
            {/* Page Heading */}
            <PageHeading heading={"For Hiring Managers"} subText={""} />

            {/* Section 1 - Your Ambit 4 Column Row */}
            <Box w="100vw" position="relative">
                <Image
                    position="absolute"
                    top="-3.4em"
                    left="0"
                    src={yourAmbitHeadingMobile}
                    display={{ base: "block", md: "none" }}
                />
                <Image
                    position="absolute"
                    top="-8em"
                    left="0"
                    src={yourAmbitHeading}
                    display={{ base: "none", md: "block" }}
                />
            </Box>
            <Flex
                direction={{ base: "column", lg: "row" }}
                mb={{ base: "5em", lg: "8em" }}
            >
                <Block
                    bgColor={"#1B4748"}
                    fontColor={"white"}
                    headingText={"Attracting the right candidates"}
                    bodyText={
                        "The best candidates have many options, but they may discard a recruitment process as quickly as they dislike an interview session."
                    }
                />
                <Block
                    bgColor={"#296968"}
                    fontColor={"white"}
                    headingText={"Retaining new hires"}
                    bodyText={
                        "Depending on the industry and discipline, many new hires are let go within 18 months of being hired, and a large part of those can simply be for attitudinal reasons."
                    }
                />
                <Block
                    bgColor={"#B3D2D1"}
                    fontColor={"black"}
                    headingText={"Providing a positive candidate experience"}
                    bodyText={
                        "A good candidate experience increases the chances of the candidate accepting the offer, and even if they are not hired they can add to a favourable impression of the organisation as an employer of choice."
                    }
                />
                <Block
                    bgColor={"#0B2D2E"}
                    fontColor={"white"}
                    headingText={"Diversity"}
                    bodyText={
                        "Workforce diversity can create cultural opportunities for an organisation to embrace change and progress beyond competitors rather than miss opportunities by remaining in a path of legacy."
                    }
                />
            </Flex>

            {/* Section 2 - Tools for success */}
            <Box w="100vw" position="relative">
                <Image
                    position="absolute"
                    top="-1.8em"
                    left="0"
                    src={yourToolsHeadingMobile}
                    display={{ base: "block", md: "none" }}
                />
                <Image
                    position="absolute"
                    top="-2em"
                    left="0"
                    src={yourToolsHeading}
                    display={{ base: "none", md: "block" }}
                />
            </Box>
            {/* White Fade Mobile */}
            <Box w="100vw" position="relative">
                <Image
                    position="absolute"
                    top="35vh"
                    left="0"
                    h="55vh"
                    w="100vw"
                    src={whiteFade}
                    display={{ base: "block", lg: "none" }}
                />
            </Box>
            <TextImage
                heading={"Your Tools For Success"}
                bodyText={[
                    "Higher Quality of Hires",
                    "Cost-Effective Recruitment",
                    "Faster Hiring Process",
                    "Improved Retention Rates",
                    "Enhanced Employee Engagement",
                    "Stronger Workplace Culture",
                    "Broader Talent Pool",
                    "Increased Employer Branding",
                    "Employee referrals: Employee referrals are an effective recruitment methods for organizations. Here are some key benefits:",
                    "Care must be given however to the possibility the referred candidates can mask their recruitment and interview participation based on coaching from existing employees.",
                    "Assessments: Using assessments during the recruitment process ensures making objective, data-driven hiring decisions. Assessments allow an organisation to evaluate candidates' skills, competencies, and cultural fit beyond what resumes and interviews reveal. They help predict job performance, reduce unconscious bias, and ensure a fair and consistent evaluation process, ultimately leading to better hires who thrive in their roles and contribute to a team's success.",
                    "Appropriate interviews: Interviews, combined with assessments, are essential in the recruitment process. Assessments provide objective data on a candidate's skills, abilities, and potential, while interviews offer the opportunity to explore their personality, communication style, and cultural fit. Together, these tools allow for a holistic evaluation, ensuring identifying the most qualified candidates but also those who align with organizational values and are likely to thrive in their roles. This integrated approach leads to more informed, fair, and successful hiring decisions.",
                ]}
                bodyTextPadding={{
                    base: "0 2.5em 0 2.5em",
                    lg: "0 3em 0 0",
                }}
                imgSrc={ladyLaptop}
                altText={"Lady at laptop"}
                imgDesktopWidth={"400px"}
                imgPositionChangeOnMobile={false}
                addFade={true}
                overflow={"scroll"}
                bodyTextMaxHeight={"65vh"}
            />
            {/* White Fade Desktop */}
            <Box w="100vw" position="relative">
                <Image
                    position="absolute"
                    top="-12em"
                    left="0"
                    h="30vh"
                    w="100vw"
                    src={whiteFade}
                    display={{ base: "none", lg: "block" }}
                />
            </Box>

            {/* Section 3 - Your challenge */}
            <Box
                w="100vw"
                position="relative"
                mt={{ base: "11em", lg: "5.5em" }}
            >
                <Image
                    position="absolute"
                    top="-7.5em"
                    left="0"
                    src={yourChallengeHeadingMobile}
                    display={{ base: "block", md: "none" }}
                />
                <Image
                    position="absolute"
                    top="-7em"
                    left="0"
                    src={yourChallengeHeading}
                    display={{ base: "none", md: "block" }}
                />
            </Box>
            <TextImageGrid
                imgOne={womenInMeeting}
                imgTwo={womanBurnout}
                showImgOne={true}
                bodyTextOne={
                    "Psychometric assessment reports are not immediately useful to line managers as they are not trained in psychology. The narratives on a psychometric report are not easily associated with job requirements. HR need to offer assistance to line managers during selection. This is most useful when comparing suitability of candidates by using a language line managers can understand and then providing them with points of reference when conducting interviews."
                }
                bodyTextTwo={
                    "Standardizing processes and artefacts across recruitment efforts - you want to use the same sources but need to apply them to different roles e.g. cognitive ability and personality for the position of Chief Actuary versus the position of Chief chemical Engineer. You don’t have a central repository for candidates and their supporting documentation. This results in looking up candidates in previous emails, or logging into third party supplier sites and downloading reports again, or requesting documents from other departments and business units. This can be amplified for repeat applicants which can result not only in duplicate effort, repeated costs but also affect the perception of the HR."
                }
                bodyTextThree={
                    "Peaks and troughs in recruitment activities can put pressure on the diligence of the recruitment processes. Recruitment activities are admin intensive but that cant result in maintaining an army of people in the recruitment department 'just in case'. Of course business cant afford to miss deadlines (offers by month end) so this demand falls squarely on hiring managers."
                }
            />

            {/* Section 4 - Case Study */}
            <CaseStudy
                csHeading="International Airport"
                date={"13/03/2021"}
                activitiesHeading={"HR Activities"}
                activitesText={
                    "This resulted in sending multiple documents to line managers, often having to construct an overall report that represented the strengths and weaknesses of a candidate in such a way that the line manager could compare relative merits of shortlisted candidates, but also assist in the interview process. The reports constructed by HR resources included measures from all three classes of assessments;- language, safety and psychometric."
                }
                description={
                    "This organisation had to recruit staff across many different airports world wide, including candidates in roughly three categories: admin staff, operational staff and technical staff."
                }
                moreInfoHeading={"A Multi-Faceted Problem"}
                moreInfo={
                    "Apart from many different levels/bands in these categories, recruitment also had to contend with different languages as well as different localised safety certifications."
                }
                imgOneMobileSrc={hrActivitiesImgMobile}
                imgOneSrc={hrActivitiesImg}
                imgTwoSrc={airportImg}
                imgTwoAlt={"Airplanes at an airport"}
            />

            {/* Section 5 - The BetaPersona Solution Point Bubbbles */}
            <Box
                w="100vw"
                position="relative"
                mt={{ base: "10.2em", lg: "7em" }}
            >
                <Image
                    position="absolute"
                    top="-7.8em"
                    left="0"
                    src={yourSolutionHeadingMobile}
                    display={{ base: "block", md: "none" }}
                />
                <Image
                    position="absolute"
                    top="-6.7em"
                    left="0"
                    src={yourSolutionHeading}
                    display={{ base: "none", md: "block" }}
                />
            </Box>
            <PointBubbles
                subheading={
                    "Betapersona.com has five main benefits for hiring managers:"
                }
                imgMobile={managersPointBubblesMobile}
                imgDesktop={managersPointBubbles}
            />

            {/* Separation between section 5 & footer */}
            <Divider
                borderBottomWidth="7px"
                opacity="1"
                borderColor={"#B3D2D1"}
            />
        </div>
    );
}
