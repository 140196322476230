import React from "react";
import PageHeading from "../components/PageHeading";
import Block from "../components/Block";
import TextImage from "../components/TextImage";
import TextImageGrid from "../components/TextImageGrid";
import PointBubbles from "../components/PointBubbles";
import { Image, Box, Divider, Heading, Text } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";
import twoLadiesAtLaptop from "../assets/two-ladies-at-laotop.webp";
import boardMeeting from "../assets/board-meeting.webp";
import officeBuilding from "../assets/office-building.webp";
import yourAmbitHeadingMobile from "../assets/Your-Ambit-heading-mobile.svg";
import yourToolsHeadingMobile from "../assets/Your-Tools-heading-mobile.svg";
import yourChallengeHeadingMobile from "../assets/Your-Challenge-heading-mobile.svg";
import yourSolutionHeadingMobile from "../assets/Your-Solution-heading-mobile.svg";
import yourAmbitHeading from "../assets/Your-Ambit-heading.svg";
import yourToolsHeading from "../assets/Your-Tools-heading.svg";
import yourChallengeHeading from "../assets/Your-Challenge-heading.svg";
import yourSolutionHeading from "../assets/Your-Solution-heading.svg";
import whiteFade from "../assets/white-fade.svg";
import professionalsPointBubblesMobile from "../assets/professionals-point-bubbles-mobile.svg";
import professionalsPointBubbles from "../assets/professionals-point-bubbles.svg";

export default function Professionals() {
    return (
        <div>
            {/* Page Heading */}
            <PageHeading
                heading={"For Registered Professionals"}
                subText={""}
            />

            {/* Section 1 - Your Ambit 4 Column Row */}
            <Box w="100vw" position="relative">
                <Image
                    position="absolute"
                    top="-3.4em"
                    left="0"
                    src={yourAmbitHeadingMobile}
                    display={{ base: "block", md: "none" }}
                />
                <Image
                    position="absolute"
                    top="-8em"
                    left="0"
                    src={yourAmbitHeading}
                    display={{ base: "none", md: "block" }}
                />
            </Box>
            <Flex
                direction={{ base: "column", lg: "row" }}
                mb={{ base: "5em", lg: "8em" }}
            >
                <Block
                    bgColor={"#1B4748"}
                    fontColor={"white"}
                    headingText={
                        "Registered psychologists play a crucial role in enhancing organizational recruitment and learning processes."
                    }
                    bodyText={
                        "In recruitment, they help design fair, evidence-based selection systems, including psychometric assessments, structured interviews, and competency frameworks. These tools ensure that hiring decisions align with organizational needs while minimizing bias. "
                    }
                />
                <Block
                    bgColor={"#296968"}
                    fontColor={"white"}
                    headingText={
                        "Psychologists also provide insights into candidate personality, cognitive abilities, and cultural fit, helping to identify individuals likely to succeed and thrive in specific roles."
                    }
                    bodyText={
                        "In learning and development, psychologists design and implement training programs tailored to enhance employee skills, leadership capabilities, and overall performance."
                    }
                />
                <Block
                    bgColor={"#B3D2D1"}
                    fontColor={"black"}
                    headingText={
                        "They use psychological principles to create engaging, effective learning experiences and evaluate their impact on behaviour and outcomes."
                    }
                    bodyText={
                        "Additionally, they coach employees and leaders to improve interpersonal skills, resilience, and adaptability, fostering a culture of continuous growth and development."
                    }
                />
                <Block
                    bgColor={"#0B2D2E"}
                    fontColor={"white"}
                    headingText={
                        "Using experts to drive measurable and long-term business gains."
                    }
                    bodyText={
                        "By integrating psychological expertise, registered psychologists help organizations attract and develop talent that drives long-term success."
                    }
                />
            </Flex>

            {/* Section 2 - Tools for success */}
            <Box w="100vw" position="relative">
                <Image
                    position="absolute"
                    top="-1.8em"
                    left="0"
                    src={yourToolsHeadingMobile}
                    display={{ base: "block", md: "none" }}
                />
                <Image
                    position="absolute"
                    top="-2em"
                    left="0"
                    src={yourToolsHeading}
                    display={{ base: "none", md: "block" }}
                />
            </Box>
            {/* White Fade Mobile */}
            <Box w="100vw" position="relative">
                <Image
                    position="absolute"
                    top="35vh"
                    left="0"
                    h="55vh"
                    w="100vw"
                    src={whiteFade}
                    display={{ base: "block", lg: "none" }}
                />
            </Box>
            <TextImage
                heading={"Your Tools For Success"}
                bodyText={[
                    <div>
                        <Heading
                            fontSize={{ base: "14px", lg: "16px" }}
                            fontWeight="medium"
                        >
                            Registered psychologists use a variety of tools to
                            help organizations achieve their goals. These tools
                            are grounded in psychological science and tailored
                            to meet the specific needs of the organization. Here
                            are some key tools:
                        </Heading>
                        <Heading
                            fontSize={{ base: "14px", lg: "16px" }}
                            fontWeight="medium"
                            textDecoration="underline"
                        >
                            Recruitment and Selection Tools:
                        </Heading>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "12px", lg: "14px" }}
                            fontWeight="medium"
                        >
                            1. Psychometric Assessments
                        </Heading>
                        <Text>
                            - Measure cognitive abilities, personality traits,
                            and emotional intelligence to predict job
                            performance and cultural fit.
                        </Text>
                        <Text>
                            - Examples: Aptitude tests, personality inventories
                            (e.g., MBTI, Hogan Assessment).
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "12px", lg: "14px" }}
                            fontWeight="medium"
                        >
                            2. Structured Interviews
                        </Heading>
                        <Text>
                            - Psychologists design and train interviewers in
                            competency-based or behavioral interviews to ensure
                            consistency and reduce bias.
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "12px", lg: "14px" }}
                            fontWeight="medium"
                        >
                            3. Assessment Centers
                        </Heading>
                        <Text>
                            - Simulations like role-plays, case studies, and
                            group exercises to evaluate candidates' skills and
                            behaviors in real-world scenarios.
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "14px", lg: "16px" }}
                            fontWeight="medium"
                            textDecoration="underline"
                        >
                            Employee Development Tools
                        </Heading>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "12px", lg: "14px" }}
                            fontWeight="medium"
                        >
                            1. 360-Degree Feedback
                        </Heading>
                        <Text>
                            - Collects feedback from peers, supervisors, and
                            direct reports to provide a comprehensive view of an
                            employee's strengths and areas for development.
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "12px", lg: "14px" }}
                            fontWeight="medium"
                        >
                            2. Training Needs Analysis
                        </Heading>
                        <Text>
                            - Tools to identify skill gaps and prioritize
                            training programs.
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "12px", lg: "14px" }}
                            fontWeight="medium"
                        >
                            3. Coaching Frameworks
                        </Heading>
                        <Text>
                            - Models like GROW (Goal, Reality, Options, Will) to
                            guide individual and leadership coaching sessions.
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "14px", lg: "16px" }}
                            fontWeight="medium"
                            textDecoration="underline"
                        >
                            Organizational Diagnostics and Change Management
                        </Heading>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "12px", lg: "14px" }}
                            fontWeight="medium"
                        >
                            1. Surveys and Questionnaires
                        </Heading>
                        <Text>
                            - Employee engagement, satisfaction, and climate
                            surveys to gather insights into workplace culture
                            and morale.
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "12px", lg: "14px" }}
                            fontWeight="medium"
                        >
                            2. Organizational Network Analysis (ONA)
                        </Heading>
                        <Text>
                            - Maps communication and collaboration patterns to
                            identify key influencers and areas for improvement.
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "12px", lg: "14px" }}
                            fontWeight="medium"
                        >
                            3. Change Readiness Assessments
                        </Heading>
                        <Text>
                            - Tools to evaluate the organization's preparedness
                            for change initiatives and guide implementation
                            strategies.
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "14px", lg: "16px" }}
                            fontWeight="medium"
                            textDecoration="underline"
                        >
                            Well-being and Mental Health Tools
                        </Heading>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "12px", lg: "14px" }}
                            fontWeight="medium"
                        >
                            1. Stress and Resilience Assessments
                        </Heading>
                        <Text>
                            - Identify factors contributing to stress and
                            develop interventions to enhance resilience.
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "12px", lg: "14px" }}
                            fontWeight="medium"
                        >
                            2. Mindfulness and Emotional Regulation Programs
                        </Heading>
                        <Text>
                            - Techniques to improve focus, reduce stress, and
                            enhance workplace well-being.
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "12px", lg: "14px" }}
                            fontWeight="medium"
                        >
                            3. Critical Incident Support Tools
                        </Heading>
                        <Text>
                            - Frameworks for responding to workplace crises,
                            such as trauma counselling or de-briefing sessions.
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "14px", lg: "16px" }}
                            fontWeight="medium"
                            textDecoration="underline"
                        >
                            Performance Management and Leadership Development
                        </Heading>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "12px", lg: "14px" }}
                            fontWeight="medium"
                        >
                            1. Competency Models
                        </Heading>
                        <Text>
                            - Define the skills and behaviours needed for
                            success in specific roles or leadership positions.
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "12px", lg: "14px" }}
                            fontWeight="medium"
                        >
                            2. Behavioural Observation Tools
                        </Heading>
                        <Text>
                            - Techniques to provide objective, constructive
                            feedback based on observed performance.
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "12px", lg: "14px" }}
                            fontWeight="medium"
                        >
                            3. Leadership Assessments
                        </Heading>
                        <Text>
                            - Tools like the Leadership Practices Inventory
                            (LPI) or the Multi-factor Leadership Questionnaire
                            (MLQ) to evaluate and develop leadership
                            capabilities.
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "14px", lg: "16px" }}
                            fontWeight="medium"
                        >
                            By leveraging these tools, registered psychologists
                            ensure their interventions are evidence-based,
                            effective, and aligned with organizational
                            objectives.
                        </Heading>
                    </div>,
                ]}
                bodyTextPadding={{ base: "0 2.5em 0 2.5em", lg: "0 3em 0 0" }}
                imgSrc={twoLadiesAtLaptop}
                altText={"Two ladies smiling at a laptop"}
                imgDesktopWidth={"400px"}
                imgPositionChangeOnMobile={false}
                addFade={true}
                overflow={"scroll"}
                bodyTextMaxHeight={"65vh"}
            />
            {/* White Fade Desktop */}
            <Box w="100vw" position="relative">
                <Image
                    position="absolute"
                    top="-12em"
                    left="0"
                    h="30vh"
                    w="100vw"
                    src={whiteFade}
                    display={{ base: "none", lg: "block" }}
                />
            </Box>

            {/* Section 3 - Your challenge */}
            <Box
                w="100vw"
                position="relative"
                mt={{ base: "11em", lg: "5.5em" }}
            >
                <Image
                    position="absolute"
                    top="-7.5em"
                    left="0"
                    src={yourChallengeHeadingMobile}
                    display={{ base: "block", md: "none" }}
                />
                <Image
                    position="absolute"
                    top="-7em"
                    left="0"
                    src={yourChallengeHeading}
                    display={{ base: "none", md: "block" }}
                />
            </Box>
            <TextImageGrid
                imgOne={boardMeeting}
                imgTwo={officeBuilding}
                showImgOne={true}
                bodyTextOne={
                    <div>
                        <Text>
                            1. Overcome resistance to change through strong
                            communication and adaptability.
                        </Text>
                        <Text>
                            2. Balance evidence-based methods with fast-paced
                            business demands.
                        </Text>
                        <Text>
                            3. Demonstrate measurable ROI for psychological
                            interventions.
                        </Text>
                        <Text>
                            4. Address time and resource constraints with
                            practical, scalable solutions.
                        </Text>
                        <Text>
                            5. Navigate and influence ingrained workplace
                            cultures.
                        </Text>
                        <Text>
                            6. Build trust and ensure confidentiality to foster
                            participation.
                        </Text>
                    </div>
                }
                bodyTextTwo={
                    <div>
                        <Text>
                            7. Stay current with trends to address rapidly
                            changing business environments.
                        </Text>
                        <Text>
                            8. Adhere to ethical guidelines when resolving
                            organizational dilemmas.
                        </Text>
                        <Text>
                            9. Tailor interventions to cultural and contextual
                            diversity in organizations.
                        </Text>
                        <Text>
                            10. Ensure data privacy and security for sensitive
                            employee information.
                        </Text>
                        <Text>
                            11. Gain buy-in from stakeholders and integrate
                            practices into business operations.
                        </Text>
                    </div>
                }
                bodyTextThree={
                    "Summary: Registered professionals navigate constraints like time, resources, and workplace culture while building trust and ensuring confidentiality. Psychologists adapt to changing trends, uphold ethical standards, tailor interventions to diverse contexts, ensure data security, and integrate their practices effectively into organizational structures."
                }
            />

            {/* Section 4 - Case Study */}
            {/* <Flex basis="100%" direction={{ base: "column", lg: "row" }}>
                <Flex
                    basis="50%"
                    direction="column"
                    p={{ base: "1em", md: "6em", lg: "5em 2em 5em 8em" }}
                >
                    <Heading
                        as="h6"
                        fontSize={{ base: "sm", lg: "md" }}
                        p={{ base: "4em 0 1em 0", lg: "3em 0 0 0" }}
                    >
                        Case Study
                    </Heading>
                    <Heading
                        as="h3"
                        fontSize={{ base: "36px", lg: "62px" }}
                        py={{ base: "0", lg: "0.8em" }}
                    >
                        The CEO
                    </Heading>
                    <Text color={"#3C3C3C"}></Text>
                </Flex>
                <Flex
                    basis="50%"
                    p={{ base: "0", md: "6em", lg: "5em 8em 5em 2em" }}
                    direction="column"
                ></Flex>
            </Flex> */}

            {/* Section 5 - The BetaPersona Solution Point Bubbbles */}
            <Box
                w="100vw"
                position="relative"
                mt={{ base: "12.2em", lg: "15em" }}
            >
                <Image
                    position="absolute"
                    top="-7.8em"
                    left="0"
                    src={yourSolutionHeadingMobile}
                    display={{ base: "block", md: "none" }}
                />
                <Image
                    position="absolute"
                    top="-9.2em"
                    left="0"
                    src={yourSolutionHeading}
                    display={{ base: "none", md: "block" }}
                />
            </Box>
            <PointBubbles
                subheading={""}
                imgMobile={professionalsPointBubblesMobile}
                imgDesktop={professionalsPointBubbles}
            />

            {/* Separation between section 5 & footer */}
            <Divider
                borderBottomWidth="7px"
                opacity="1"
                borderColor={"#B3D2D1"}
            />
        </div>
    );
}
