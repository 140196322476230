import React from "react";
import {
    Text,
    Image,
    Box,
    Stack,
    VStack,
    HStack,
    Link,
    Button,
    Divider,
} from "@chakra-ui/react";
import linkedInIcon from "../assets/LinkedIn-icon.svg";
import logoBlack from "../assets/logo-black.svg";

export default function Footer() {
    return (
        <Box>
            <Box
                bg={"#1B4748"}
                p={{ base: "2em 1.5em 2em 1.5em", lg: "4em 3em 1em 3em" }}
            >
                <Stack
                    direction={{ base: "column", lg: "row" }}
                    spacing={3}
                    align="center"
                    p={{ base: "2em", lg: "0" }}
                >
                    {/* Contact Us */}
                    <VStack
                        align="space-between"
                        w={{ base: "100%", lg: "35%" }}
                        m={{
                            base: "1em 0em 1em 0em",
                            lg: "2.5em 5em 2.5em 5em",
                        }}
                    >
                        <HStack justify="space-between" color="white">
                            <Text>Follow us on LinkedIn</Text>
                            <Link
                                href="https://www.linkedin.com/products/digitalfields-betapersonacom/"
                                target="_blank"
                            >
                                <Image src={linkedInIcon}></Image>
                            </Link>
                        </HStack>
                        <HStack justify={"space-between"} color="white">
                            <Text>Chat to us</Text>
                            <Link
                                href="https://calendly.com/lawriefraser/30min"
                                target="_blank"
                            >
                                <Button
                                    bg={"#F8641E"}
                                    color="white"
                                    fontWeight="500"
                                    _hover={{ bg: "rgba(248, 100, 30, 0.7)" }}
                                >
                                    Book an appointment
                                </Button>
                            </Link>
                        </HStack>
                    </VStack>

                    {/* About Us */}
                    <VStack
                        w={{ base: "100%", lg: "30%" }}
                        m={{
                            base: "4em 0em 3em 0em",
                            lg: "2.5em",
                        }}
                    >
                        <Text
                            fontSize={{ base: "22px", md: "18px" }}
                            color="white"
                            fontWeight="bold"
                        >
                            About Us
                        </Text>
                        <Text color="white">
                            <Link
                                href="betapersona.com"
                                textDecoration="underline"
                            >
                                betapersona.com
                            </Link>{" "}
                            <pre>A product of:</pre>
                            <pre>Digitalfields Europe Ltd</pre>
                            <pre>Reg no. 15999199</pre>
                            <pre>85 Great Portland Street</pre>
                            <pre>First Floor</pre>
                            <pre>London</pre>
                            <pre>W1W 7LT</pre>
                            <pre>UNITED KINGDOM</pre>
                        </Text>
                    </VStack>

                    {/* Page Navigation */}
                    <VStack
                        w={{ base: "100%", lg: "35%" }}
                        m={{
                            base: "1em 0em 1em 0em",
                            lg: "2.5em 5em 2.5em 5em",
                        }}
                    >
                        <Link to="/">
                            <Button
                                variant="link"
                                fontSize={{ base: "20px", md: "18px" }}
                                color="white"
                                pr={6}
                                m={0}
                            >
                                HomePage
                            </Button>
                        </Link>
                        <Link to="/Managers">
                            <Button
                                variant="link"
                                fontSize={{ base: "20px", md: "18px" }}
                                color="white"
                                pr={6}
                                m={0}
                            >
                                For hiring managers
                            </Button>
                        </Link>
                        <Link to="/HRBP">
                            <Button
                                variant="link"
                                fontSize={{ base: "20px", md: "18px" }}
                                color="white"
                                pr={6}
                                m={0}
                            >
                                For HRBP's
                            </Button>
                        </Link>
                        <Link to="/Recruiters">
                            <Button
                                variant="link"
                                fontSize={{ base: "20px", md: "18px" }}
                                color="white"
                                pr={6}
                                m={0}
                            >
                                For recruiters
                            </Button>
                        </Link>
                        <Link to="/Professionals">
                            <Button
                                variant="link"
                                fontSize={{ base: "20px", md: "18px" }}
                                color="white"
                                pr={6}
                                m={0}
                            >
                                For registered professionals
                            </Button>
                        </Link>
                    </VStack>
                </Stack>
                <Divider
                    borderBottomWidth="0.8px"
                    opacity="1"
                    borderColor="white"
                />
                <Text
                    textAlign="center"
                    color="white"
                    fontSize={{ md: "16px", lg: "14px" }}
                    pt="5"
                >
                    The term "betapersona": could potentially blend the concepts
                    of the "beta" test phase and "persona," referring to the
                    trial state of an individual's projected image or behavior.
                    In this interpretation, a "betapersona" might describe a
                    persona that is still in development or testing—one that is
                    not fully formed, much like software in its beta phase. It
                    could be a way to signify someone who is experimenting with
                    different aspects of their identity, behavior, or role in
                    social or professional settings, trying to find what works
                    before settling on a more definitive "final version" of
                    themselves. (ChatGpt - 4 October 2024)
                </Text>
            </Box>
            <Box bg={"#B3D2D1"} p="3">
                <Image src={logoBlack} alt="BetaPersona Logo" />
            </Box>
        </Box>
    );
}
