import React from "react";
import { Heading, Text, Flex, Image } from "@chakra-ui/react";

export default function CaseStudy({
    csHeading,
    date,
    description,
    activitiesHeading,
    activitesText,
    moreInfoHeading,
    moreInfo,
    imgOneMobileSrc,
    imgOneSrc,
    imgTwoSrc,
    imgTwoAlt,
}) {
    return (
        <Flex basis="100%" direction={{ base: "column", lg: "row" }}>
            <Flex
                basis="50%"
                direction="column"
                p={{ base: "1em", md: "6em", lg: "5em 2em 5em 8em" }}
            >
                <Heading
                    as="h6"
                    fontSize={{ base: "sm", lg: "md" }}
                    p={{ base: "4em 0 1em 0", lg: "3em 0 0 0" }}
                >
                    Case Study
                </Heading>
                <Heading
                    as="h3"
                    fontSize={{ base: "36px", lg: "62px" }}
                    py={{ base: "0", lg: "0.8em" }}
                >
                    {csHeading}
                </Heading>
                <div>
                    <Text fontWeight="bold" m="0">
                        Date
                    </Text>
                    <Text>{date}</Text>
                </div>
                <div>
                    <Text fontWeight="bold">{activitiesHeading}</Text>
                    <Image
                        src={imgOneMobileSrc}
                        alt="List of various HR activities"
                        display={{ base: "block", md: "none" }}
                        p={"1em 1.6em 1.6em 0"}
                    />
                    <Image
                        src={imgOneSrc}
                        alt="List of various HR activities"
                        display={{ base: "none", md: "block" }}
                        p={"1em 1.6em 1.6em 0"}
                    />
                </div>
                <Text color={"#3C3C3C"}>{activitesText}</Text>
            </Flex>
            <Flex
                basis="50%"
                p={{ base: "0", md: "6em", lg: "5em 8em 5em 2em" }}
                direction="column"
            >
                <Text
                    fontWeight="bold"
                    m="0"
                    p={{ base: "0 3em 0 3em", md: "0", lg: "6.5em 0 0 0 " }}
                >
                    Description
                </Text>
                <Text
                    color={"#3C3C3C"}
                    p={{ base: "0 0.2em 0 0.2em", md: "0", lg: "0.5em 0 0 0 " }}
                    fontSize="lg"
                >
                    {description}
                </Text>
                <div>
                    <Heading
                        p={{
                            base: "0 1.5em 0 1.5em",
                            md: "0",
                            lg: "1.5em 0 0 0",
                        }}
                    >
                        {moreInfoHeading}
                    </Heading>
                    <Text fontWeight="500" p={{ base: "0 3em 0 3em", md: "0" }}>
                        {moreInfo}
                    </Text>
                </div>
                <Image
                    src={imgTwoSrc}
                    alt={imgTwoAlt}
                    pt={{ base: "0", lg: "4em" }}
                />
            </Flex>
        </Flex>
    );
}
