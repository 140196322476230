import React from "react";
import PageHeading from "../components/PageHeading";
import Block from "../components/Block";
import TextImage from "../components/TextImage";
import TextImageGrid from "../components/TextImageGrid";
import PointBubbles from "../components/PointBubbles";
import { Image, Box, Divider, Heading, Text } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";
import ladiesByMicrosoftLaptop from "../assets/ladies-by-microsoft-laptop.webp";
import explainingBoardroomMeeting from "../assets/explaining-boardroom-meeting.png";
import ladiesInMeeting from "../assets/ladies-in-meeting.webp";
import yourAmbitHeadingMobile from "../assets/Your-Ambit-heading-mobile.svg";
import yourToolsHeadingMobile from "../assets/Your-Tools-heading-mobile.svg";
import yourChallengeHeadingMobile from "../assets/Your-Challenge-heading-mobile.svg";
import yourSolutionHeadingMobile from "../assets/Your-Solution-heading-mobile.svg";
import yourAmbitHeading from "../assets/Your-Ambit-heading.svg";
import yourToolsHeading from "../assets/Your-Tools-heading.svg";
import yourChallengeHeading from "../assets/Your-Challenge-heading.svg";
import yourSolutionHeading from "../assets/Your-Solution-heading.svg";
import whiteFade from "../assets/white-fade.svg";
import recruitersPointBubblesMobile from "../assets/recruiters-point-bubbles-mobile.svg";
import recruitersPointBubbles from "../assets/recruiters-point-bubbles.svg";

export default function Recruiters() {
    return (
        <div>
            {/* Page Heading */}
            <PageHeading heading={"For Recruiters"} subText={""} />

            {/* Section 1 - Your Ambit 4 Column Row */}
            <Box w="100vw" position="relative">
                <Image
                    position="absolute"
                    top="-3.4em"
                    left="0"
                    src={yourAmbitHeadingMobile}
                    display={{ base: "block", md: "none" }}
                />
                <Image
                    position="absolute"
                    top="-8em"
                    left="0"
                    src={yourAmbitHeading}
                    display={{ base: "none", md: "block" }}
                />
            </Box>
            <Flex direction={{ base: "column", lg: "row" }}>
                <Block
                    bgColor={"#1B4748"}
                    fontColor={"white"}
                    headingText={"Understanding Hiring Needs"}
                    bodyText={
                        "Identify job requirements, qualifications, competencies, ideal candidate profiles and develop job descriptions."
                    }
                />
                <Block
                    bgColor={"#296968"}
                    fontColor={"white"}
                    headingText={"Sourcing Candidates"}
                    bodyText={
                        "Attract talent through job boards, social media, professional networks, agencies, and passive candidate outreach."
                    }
                />
                <Block
                    bgColor={"#B3D2D1"}
                    fontColor={"black"}
                    headingText={"Screening and Shortlisting"}
                    bodyText={
                        "Review applications, conduct initial interviews, and shortlist suitable candidates."
                    }
                />
                <Block
                    bgColor={"#0B2D2E"}
                    fontColor={"white"}
                    headingText={"Coordinating Interviews"}
                    bodyText={
                        "Schedule and facilitate interviews, ensuring a smooth process for candidates and hiring teams."
                    }
                />
                <Block
                    bgColor={"#B3D2D1"}
                    fontColor={"black"}
                    headingText={"Employer Branding"}
                    bodyText={
                        "Enhance the company’s image through marketing, highlighting culture, values, and benefits."
                    }
                />
            </Flex>
            <Flex
                direction={{ base: "column", lg: "row" }}
                mb={{ base: "5em", lg: "8em" }}
            >
                <Block
                    bgColor={"#0B2D2E"}
                    fontColor={"white"}
                    headingText={"Candidate Relationship Management"}
                    bodyText={
                        "Maintain positive relationships, provide updates, and guide candidates through the hiring process."
                    }
                />
                <Block
                    bgColor={"#B3D2D1"}
                    fontColor={"black"}
                    headingText={"Negotiating Offers"}
                    bodyText={
                        "Present and negotiate offers, aligning candidate expectations with company terms."
                    }
                />
                <Block
                    bgColor={"#296968"}
                    fontColor={"white"}
                    headingText={"Onboarding Assistance"}
                    bodyText={
                        "Support new hires’ transitions by collaborating with HR and providing necessary resources."
                    }
                />
                <Block
                    bgColor={"#1B4748"}
                    fontColor={"white"}
                    headingText={"Talent Pipelines"}
                    bodyText={
                        "Build and maintain a database of potential candidates for future needs."
                    }
                />
                <Block
                    bgColor={"#296968"}
                    fontColor={"white"}
                    headingText={"Compliance and Reporting"}
                    bodyText={
                        "Ensure hiring practices meet legal standards and document recruitment metrics."
                    }
                />
            </Flex>

            {/* Section 2 - Tools for success */}
            <Box w="100vw" position="relative">
                <Image
                    position="absolute"
                    top="-1.8em"
                    left="0"
                    src={yourToolsHeadingMobile}
                    display={{ base: "block", md: "none" }}
                />
                <Image
                    position="absolute"
                    top="-2em"
                    left="0"
                    src={yourToolsHeading}
                    display={{ base: "none", md: "block" }}
                />
            </Box>
            {/* White Fade Mobile */}
            <Box w="100vw" position="relative">
                <Image
                    position="absolute"
                    top="35vh"
                    left="0"
                    h="55vh"
                    w="100vw"
                    src={whiteFade}
                    display={{ base: "block", lg: "none" }}
                />
            </Box>
            <TextImage
                heading={"Common Tools For Success"}
                bodyText={[
                    <div>
                        <Heading
                            fontSize={{ base: "12px", lg: "14px" }}
                            fontWeight="medium"
                        >
                            Sourcing Platforms and job boards:
                        </Heading>
                        <Text>
                            Identify and attract candidates by listing,
                            searching databases and professional networks.
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "12px", lg: "14px" }}
                            fontWeight="medium"
                        >
                            Applicant Tracking Systems (ATS):
                        </Heading>
                        <Text>
                            Manage job applications, track candidates, and
                            streamline the hiring process.
                        </Text>
                        <Heading
                            fontSize={{ base: "12px", lg: "14px" }}
                            fontWeight="medium"
                        >
                            Video Interview Platforms:
                        </Heading>
                        <Text>Conduct remote interviews efficiently</Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "12px", lg: "14px" }}
                            fontWeight="medium"
                        >
                            AI and Automation Tools:
                        </Heading>
                        <Text>
                            Automate repetitive tasks, improve screening, and
                            match candidates to job descriptions.
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "12px", lg: "14px" }}
                            fontWeight="medium"
                        >
                            Assessment and Testing Platforms:
                        </Heading>
                        <Text>
                            Evaluate candidates' skills, aptitude, competencies
                            and cultural fit.
                        </Text>
                    </div>,
                ]}
                bodyTextPadding={{ base: "0 2.5em 0 2.5em", lg: "0 3em 0 0" }}
                imgSrc={ladiesByMicrosoftLaptop}
                altText={"Two ladies smiling at a laptop"}
                imgDesktopWidth={"400px"}
                imgPositionChangeOnMobile={false}
                addFade={true}
                overflow={"scroll"}
                bodyTextMaxHeight={"65vh"}
            />
            {/* White Fade Desktop */}
            <Box w="100vw" position="relative">
                <Image
                    position="absolute"
                    top="-12em"
                    left="0"
                    h="30vh"
                    w="100vw"
                    src={whiteFade}
                    display={{ base: "none", lg: "block" }}
                />
            </Box>

            {/* Section 3 - Your challenge */}
            <Box
                w="100vw"
                position="relative"
                mt={{ base: "11em", lg: "5.5em" }}
            >
                <Image
                    position="absolute"
                    top="-7.5em"
                    left="0"
                    src={yourChallengeHeadingMobile}
                    display={{ base: "block", md: "none" }}
                />
                <Image
                    position="absolute"
                    top="-7em"
                    left="0"
                    src={yourChallengeHeading}
                    display={{ base: "none", md: "block" }}
                />
            </Box>
            <TextImageGrid
                imgOne={explainingBoardroomMeeting}
                imgTwo={ladiesInMeeting}
                showImgOne={true}
                bodyTextOne={
                    <div>
                        <Heading
                            fontSize={{ base: "14px", lg: "16px" }}
                            fontWeight="medium"
                        >
                            1. Finding the right talent in a competitive market.
                            But what is ‘right’ talent? At junior levels the
                            focus is likely their CV, comprising qualifications
                            and experience. At senior levels it is likely their
                            experience and references. The nuance is as follows:
                        </Heading>
                        <Text>
                            a. If the position requires someone to affect a
                            known capacity/function, do they compliment and
                            strengthen existing competencies in the rest of the
                            team/department.
                        </Text>
                        <Text>
                            b. If the position requires progression of the role,
                            can the candidate evolve that to the required level.
                        </Text>
                        <Heading
                            fontSize={{ base: "14px", lg: "16px" }}
                            fontWeight="medium"
                        >
                            2. Rapidly changing technology: Candidates are
                            increasingly using AI to draft their CV and cover
                            letter specific to the posted job advert. While this
                            does represent contemporary initiative, there is
                            very little to evaluate the actual character of the
                            candidate before an interview, hence the increased
                            need for assessments.
                        </Heading>
                    </div>
                }
                bodyTextTwo={
                    <div>
                        <Heading
                            fontSize={{ base: "14px", lg: "16px" }}
                            fontWeight="medium"
                        >
                            3. Managing the expectations of both employers and
                            candidates. After presenting possible candidates to
                            department heads or line managers, merits of a
                            candidate can be obvious to the HR discipline, but
                            lost to business stakeholders.
                        </Heading>
                        <Heading
                            fontSize={{ base: "14px", lg: "16px" }}
                            fontWeight="medium"
                        >
                            4. Candidate perception. Applicants getting no
                            responses is a common frustration, but that becomes
                            even more intense when candidates have made it
                            through a battery of assessments only to be left
                            with little or no feedback based on their efforts,
                            perceiving them as a pointless or a mere power-play
                            by HR.
                        </Heading>
                    </div>
                }
                bodyTextThree={
                    "Summary: The ultimate goal is to choose the most appropriate candidate and onboard them in the correct context. This prime objective can become diluted against competing focus in the overall recruitment workflow. Add to this the need to reduce bias and legacy encountered with business stakeholders, you cant just rely 100% on the interview skills of the relevant line manager. This implies the need for scientific measures and standardized comparisons. The increased use of competency or psychometric assessments in the recruitment process helps HR shortlist and recommend candidates, but the merits of those assessments must be presented in a format that business stakeholders can understand and rely on. In cases where line managers are not well skilled in interview techniques, they need to be presented with information that assists and guides them. In cases where the line manager is a seasoned interviewer, they need to be presented in a manner to reduce bias and legacy decision making."
                }
            />

            {/* Section 4 - Case Study */}
            {/* <Flex basis="100%" direction={{ base: "column", lg: "row" }}>
                <Flex
                    basis="50%"
                    direction="column"
                    p={{ base: "1em", md: "6em", lg: "5em 2em 5em 8em" }}
                >
                    <Heading
                        as="h6"
                        fontSize={{ base: "sm", lg: "md" }}
                        p={{ base: "4em 0 1em 0", lg: "3em 0 0 0" }}
                    >
                        Case Study
                    </Heading>
                    <Heading
                        as="h3"
                        fontSize={{ base: "36px", lg: "62px" }}
                        py={{ base: "0", lg: "0.8em" }}
                    >
                        The CEO
                    </Heading>
                    <Text color={"#3C3C3C"}></Text>
                </Flex>
                <Flex
                    basis="50%"
                    p={{ base: "0", md: "6em", lg: "5em 8em 5em 2em" }}
                    direction="column"
                ></Flex>
            </Flex> */}

            {/* Section 5 - The BetaPersona Solution Point Bubbbles */}
            <Box
                w="100vw"
                position="relative"
                mt={{ base: "12.2em", lg: "15em" }}
            >
                <Image
                    position="absolute"
                    top="-7.8em"
                    left="0"
                    src={yourSolutionHeadingMobile}
                    display={{ base: "block", md: "none" }}
                />
                <Image
                    position="absolute"
                    top="-9.2em"
                    left="0"
                    src={yourSolutionHeading}
                    display={{ base: "none", md: "block" }}
                />
            </Box>
            <PointBubbles
                subheading={""}
                imgMobile={recruitersPointBubblesMobile}
                imgDesktop={recruitersPointBubbles}
            />

            {/* Separation between section 5 & footer */}
            <Divider
                borderBottomWidth="7px"
                opacity="1"
                borderColor={"#B3D2D1"}
            />
        </div>
    );
}
