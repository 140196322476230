import React from "react";
import PageHeading from "../components/PageHeading";
import Block from "../components/Block";
import TextImage from "../components/TextImage";
import TextImageGrid from "../components/TextImageGrid";
import CaseStudy from "../components/CaseStudy";
import PointBubbles from "../components/PointBubbles";
import { Image, Box, Divider, Heading, Text } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";
import ladiesAtLaptop from "../assets/older-ladies-looking-laptop.webp";
import foldedhandsLaptop from "../assets/foldedhands-laptop.webp";
import HRBPInitiativesMobile from "../assets/HRBP-initiatives-mobile.svg";
import HRBPInitiativesImg from "../assets/HRBP-initiatives.svg";
import stethoscopeImg from "../assets/stethoscope.webp";
import yourAmbitHeadingMobile from "../assets/Your-Ambit-heading-mobile.svg";
import yourToolsHeadingMobile from "../assets/Your-Tools-heading-mobile.svg";
import yourChallengeHeadingMobile from "../assets/Your-Challenge-heading-mobile.svg";
import yourSolutionHeadingMobile from "../assets/Your-Solution-heading-mobile.svg";
import yourAmbitHeading from "../assets/Your-Ambit-heading.svg";
import yourToolsHeading from "../assets/Your-Tools-heading.svg";
import yourChallengeHeading from "../assets/Your-Challenge-heading.svg";
import yourSolutionHeading from "../assets/Your-Solution-heading.svg";
import whiteFade from "../assets/white-fade.svg";
import HRBPPointBubblesMobile from "../assets/HRBP-point-bubbles-mobile.svg";
import HRBPPointBubbles from "../assets/HRBP-point-bubbles.svg";

export default function HRBP() {
    return (
        <div>
            {/* Page Heading */}
            <PageHeading heading={"For HR Business Partners"} subText={""} />

            {/* Section 1 - Your Ambit 4 Column Row */}
            <Box w="100vw" position="relative">
                <Image
                    position="absolute"
                    top="-3.4em"
                    left="0"
                    src={yourAmbitHeadingMobile}
                    display={{ base: "block", md: "none" }}
                />
                <Image
                    position="absolute"
                    top="-8em"
                    left="0"
                    src={yourAmbitHeading}
                    display={{ base: "none", md: "block" }}
                />
            </Box>
            <Flex
                direction={{ base: "column", lg: "row" }}
                mb={{ base: "5em", lg: "8em" }}
            >
                <Block
                    bgColor={"#1B4748"}
                    fontColor={"white"}
                    headingText={"Aligning HR with business goals"}
                    bodyText={
                        "HRBPs act as consultants to ensure that HR practices support the organization's success by aligning with business goals. Those business goals can change radically within a year and come with peaks and troughs. Often speed and agility of HR services falls squarely on the HRBP. "
                    }
                />
                <Block
                    bgColor={"#296968"}
                    fontColor={"white"}
                    headingText={"Providing strategic input"}
                    bodyText={
                        "HRBPs provide strategic advice and input on people-related issues, such as: Talent management Employee relations Coaching and developmentCompensation Conflict management Organizational development Training. This input is expected to be backed with empirical evidence as well as offer predictions on future trends. "
                    }
                />
                <Block
                    bgColor={"#B3D2D1"}
                    fontColor={"black"}
                    headingText={"Collaborating with executives"}
                    bodyText={
                        "HRBPs collaborate with executives and may even sit on the board of directors. This requires contact feedback to executives on operational activities. "
                    }
                />
                <Block
                    bgColor={"#0B2D2E"}
                    fontColor={"white"}
                    headingText={"Develop and coach department heads"}
                    bodyText={
                        "Department heads look to their HRBP to advise on the recruitment and development of their teams. This advise has to be given in business-language and the more department heads are empowered to understand the underlying aspects of their work force the more likely they are to take responsibility for decisions and the less dependant they are on the HRBP for each step in the recruitment and development cycles."
                    }
                />
            </Flex>

            {/* Section 2 - Tools for success */}
            <Box w="100vw" position="relative">
                <Image
                    position="absolute"
                    top="-1.8em"
                    left="0"
                    src={yourToolsHeadingMobile}
                    display={{ base: "block", md: "none" }}
                />
                <Image
                    position="absolute"
                    top="-2em"
                    left="0"
                    src={yourToolsHeading}
                    display={{ base: "none", md: "block" }}
                />
            </Box>
            {/* White Fade Mobile */}
            <Box w="100vw" position="relative">
                <Image
                    position="absolute"
                    top="35vh"
                    left="0"
                    h="55vh"
                    w="100vw"
                    src={whiteFade}
                    display={{ base: "block", lg: "none" }}
                />
            </Box>
            <TextImage
                heading={"Your Tools For Success"}
                bodyText={[
                    <div>
                        <Heading
                            fontSize={{ base: "14px", lg: "16px" }}
                            fontWeight="medium"
                            textDecoration="underline"
                        >
                            Recruitment and applicant tracking systems
                        </Heading>
                        <Text>- Define competencies for the job advert</Text>
                        <Text>
                            - Word the job advert to attract the correct
                            candidate
                        </Text>
                        <Text>- Monitor applicants and shortlist</Text>
                        <Text>
                            - Ensure shortlisted candidates perform relative
                            assessments
                        </Text>
                        <Text>
                            - Generate combined/integrated report for
                            shortlisted candidates
                        </Text>
                        <Text>
                            - Present shortlisted candidates to line
                            managers/department heads with all relevant
                            information
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "14px", lg: "16px" }}
                            fontWeight="medium"
                            textDecoration="underline"
                        >
                            Employee engagement and feedback systems
                        </Heading>
                        <Heading
                            fontSize={{ base: "12px", lg: "14px" }}
                            fontWeight="medium"
                        >
                            Performance assessments
                        </Heading>
                        <Text>
                            Help define performance outcomes to be assessed
                        </Text>
                        <Text>
                            Setup templates or systems to be used during
                            performance reviews
                        </Text>
                        <Text>
                            Feedback outcomes to department heads and executives
                        </Text>
                        <Text>
                            Review outcomes and advise development activities
                            needed
                        </Text>
                        <Heading
                            fontSize={{ base: "12px", lg: "14px" }}
                            fontWeight="medium"
                        >
                            360 assessments
                        </Heading>

                        <Text>Help define competencies to be rated</Text>
                        <Text>
                            Setup templates or systems to rate competencies
                        </Text>
                        <Text>
                            Feedback outcomes to department heads and executives
                        </Text>
                        <Text>
                            Review outcomes and advise development activities
                            needed
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "14px", lg: "16px" }}
                            fontWeight="medium"
                            textDecoration="underline"
                        >
                            HRIS
                        </Heading>
                        <Text>
                            A software system that stores and manages employee
                            data, such as payroll, benefits, performance
                            reviews, and training records. The goal of HRIS is
                            to streamline HR data management so that people
                            teams can access, update, and analyze data more
                            easily.
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "14px", lg: "16px" }}
                            fontWeight="medium"
                            textDecoration="underline"
                        >
                            HCM
                        </Heading>
                        <Text>
                            A set of practices and tools that help organizations
                            attract, recruit, train, develop, manage, and retain
                            employees. The goal of HCM is to maximize the
                            collective human capital of a workforce by improving
                            essential experiences and processes.
                        </Text>
                    </div>,
                ]}
                bodyTextPadding={{ base: "0 2.5em 0 2.5em", lg: "0 3em 0 0" }}
                imgSrc={ladiesAtLaptop}
                altText={"Lady at laptop"}
                imgDesktopWidth={"400px"}
                imgPositionChangeOnMobile={false}
                addFade={true}
                overflow={"scroll"}
                bodyTextMaxHeight={"65vh"}
            />
            {/* White Fade Desktop */}
            <Box w="100vw" position="relative">
                <Image
                    position="absolute"
                    top="-12em"
                    left="0"
                    h="30vh"
                    w="100vw"
                    src={whiteFade}
                    display={{ base: "none", lg: "block" }}
                />
            </Box>

            {/* Section 3 - Your challenge */}
            <Box
                w="100vw"
                position="relative"
                mt={{ base: "11em", lg: "5.5em" }}
            >
                <Image
                    position="absolute"
                    top="-7.5em"
                    left="0"
                    src={yourChallengeHeadingMobile}
                    display={{ base: "block", md: "none" }}
                />
                <Image
                    position="absolute"
                    top="-7em"
                    left="0"
                    src={yourChallengeHeading}
                    display={{ base: "none", md: "block" }}
                />
            </Box>
            <TextImageGrid
                imgOne={foldedhandsLaptop}
                imgTwo={foldedhandsLaptop}
                showImgOne={false}
                bodyTextOne={[
                    <div>
                        <Text textDecoration="underline" fontSize="xl">
                            Defined Challenges
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "14px", lg: "16px" }}
                            fontWeight="medium"
                        >
                            1. Attracting ‘top’ talent
                        </Heading>
                        <Text>What do we mean by ‘top’ talent?</Text>
                        <Text>- Is it the best grades in their degree?</Text>
                        <Text>
                            - Is it the length of their employment on CV? 
                        </Text>
                        <Text>
                            - Is it the most glowing references on LinkedIn? 
                        </Text>
                        <Text>
                            - Did we word the job advert to attract the correct
                            personality type?
                        </Text>
                        <Text>
                            - Did the applicant use AI to format their CV and
                            cover letter to match our job advert? 
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "14px", lg: "16px" }}
                            fontWeight="medium"
                        >
                            2. Training and development
                        </Heading>
                        <Text>
                            You need to understand the underlying nature
                            (psychology) of a person in the context of their
                            performance history as well as how their colleagues
                            perceive them to ensure you can develop someone to
                            their potential in the organization. How do we get
                            the holistic view of our talent?
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "14px", lg: "16px" }}
                            fontWeight="medium"
                        >
                            3. Build digital agility
                        </Heading>
                        <Text>
                            You need to show that the efforts of HRBP’s are
                            leveraging systems and standards such that business
                            stakeholders have faith in the processes and
                            outcomes.
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "14px", lg: "16px" }}
                            fontWeight="medium"
                        >
                            4. Managing diversity
                        </Heading>
                        <Text>
                            Diversity means different things to different
                            organizations, but how do we look beyond the obvious
                            and address diversity of competencies in a
                            team/department. How does an HRBP motivate a recruit
                            or a training effort to ensure complimentary
                            competencies to reduce polarity or legacy of skills
                            and ensure a more rounded capacity?
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "14px", lg: "16px" }}
                            fontWeight="medium"
                        >
                            5. Leadership development
                        </Heading>
                        <Text>
                            Not everyone is a leader, but there are leadership
                            skills that can be learnt. Surely the psychology of
                            a person needs to be at the foundation of that
                            development path, but the power comes from measuring
                            their performance over time in context of the
                            development efforts undertaken.
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "14px", lg: "16px" }}
                            fontWeight="medium"
                        >
                            6. Employee health and well being
                        </Heading>
                        <Text>
                            A growing class of assessments for employees that is
                            becoming more and more popular, but how do we
                            measure and report on them in context of their other
                            information.
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "14px", lg: "16px" }}
                            fontWeight="medium"
                        >
                            7. Get ‘AI’ ready
                        </Heading>
                        <Text>
                            It all begins with data, so you need many lenses on
                            your talent pool in one place accumulating  over a
                            period of time before you can even entertain
                            AI-enablement.
                        </Text>
                    </div>,
                    <div>
                        <Text textDecoration="underline" fontSize="xl">
                            Day-to-day Challenges
                        </Text>
                        <Text>
                            Seasonal peaks in effort such as annual
                            intern/graduates represent an impact on volume and
                            standardization. Plus speed is of the essence as you
                            are often competing with others in the same time
                            scale.
                        </Text>
                    </div>,
                    <div>
                        <Heading
                            fontSize={{ base: "14px", lg: "16px" }}
                            fontWeight="medium"
                        >
                            Access to previous assessments and reports (do you
                            even know if they exist).
                        </Heading>
                        <Text>
                            - You performed assessments during recruitment but
                            now need access to them for development efforts.
                        </Text>
                        <Text>
                            - Resources move within an organization, even
                            globally,  but they have previous assessments stored
                            in different divisions/departments, even on
                            different HRBP’s laptops.
                        </Text>
                        <Text>
                            - Assessment reports can be stored on third party
                            assessment systems – do you still have access, do
                            you have the right permissions?
                        </Text>
                        <Text>
                            - Can you see previous reports sent to business
                            stakeholders that were constructed by individuals
                            based on their interpretations of assessment
                            reports.
                        </Text>
                        <Text>
                            - Poor or inconsistent access to previous assessment
                            and HRBP reports will result in repeated assessments
                            of same person, repetitive tasks (generating the
                            same report again), conflicting report formats based
                            on different authors as well as delays trying to
                            source previous records.
                        </Text>
                    </div>,
                ]}
                bodyTextTwo={
                    "You have qualified HR professionals manually sourcing and collating information before they can apply their discernment to produce reports. Under time/volume constraints the temptation is to forward assessment reports to business stakeholders ‘as is’, effectively leaving (unqualified) department heads and line managers unable to interpret the reports effectively and therefore degrading the reputation of the HRBP function. This may in fact become counter-productive as the business stakeholders may request time from HRBPs to explain the reports anyway. The less business stakeholders feel HRBPs are assisting them, the less likely they are to conform to the defined HR processes. Standardisation may require that all reports generated by HRBP for business stakeholders have to be review centrally for content and layout before they can be sent out. This is needed to ensure quality of the HR services offered to business given different levels of HR resources and different requirements between divisions/departments. "
                }
                bodyTextThree={
                    "Summary: You can get a lot of data on employees and potential hires from various systems, and you can get analytics on your workforce, but all these systems imply that the information is captured after the decisions were affected. Where do these systems provide timely information in a business friendly format used by business stakeholder to assist in the actual decision making progress before that?"
                }
            />

            {/* Section 4 - Case Study */}
            <CaseStudy
                csHeading={"Leading Medical Aid"}
                date={"12/09/2024"}
                activitiesHeading={
                    "So the question became immediately prominent – how do we measure the potential candidates in order to prove a trend? This resulted in two major initiatives:"
                }
                activitesText={
                    "The result was multiple battery combinations of competency assessments collated into an integrated report for comparison and selection of appropriate candidates. Similar batteries were used to assess existing talent to ascertain their learning and development paths while also allowing comparison during recruitment efforts. HRBP’s and line managers had access to the integrated reports which contained contextual measures that did not require training in psychometrics and assisted timely decision making. "
                }
                description={
                    "The CEO of this organization expressed a simple requirement from HR - “Show me that we are hiring better skilled people today then last year”. This is an interesting strategy, the theory is that if HR can prove this trend, it will manifest in the performance of the group. If this wasn’t a challenge in itself, a major component was that the actuarial discipline wanted a different lens on their talent. In their minds actuarial talent were a vastly different kind of person to the rest of the group, and in their minds closer to the heart of the actual product offering of the group."
                }
                moreInfoHeading={""}
                moreInfo={""}
                imgOneMobileSrc={HRBPInitiativesMobile}
                imgOneSrc={HRBPInitiativesImg}
                imgTwoSrc={stethoscopeImg}
                imgTwoAlt={"Stethoscope on table"}
            />

            {/* Section 5 - The BetaPersona Solution Point Bubbbles */}
            <Box
                w="100vw"
                position="relative"
                mt={{ base: "10.2em", lg: "7em" }}
            >
                <Image
                    position="absolute"
                    top="-7.8em"
                    left="0"
                    src={yourSolutionHeadingMobile}
                    display={{ base: "block", md: "none" }}
                />
                <Image
                    position="absolute"
                    top="-6.7em"
                    left="0"
                    src={yourSolutionHeading}
                    display={{ base: "none", md: "block" }}
                />
            </Box>
            <PointBubbles
                subheading={""}
                imgMobile={HRBPPointBubblesMobile}
                imgDesktop={HRBPPointBubbles}
            />

            {/* Separation between section 5 & footer */}
            <Divider
                borderBottomWidth="7px"
                opacity="1"
                borderColor={"#B3D2D1"}
            />
        </div>
    );
}
