import React from "react";
import { Heading, Text, Flex } from "@chakra-ui/react";

export default function Block({ bgColor, fontColor, headingText, bodyText }) {
    return (
        <Flex
            basis="100%"
            p="12"
            direction="column"
            backgroundColor={bgColor}
            color={fontColor}
        >
            <Heading as="h6" fontSize={{ base: "sm", lg: "md" }}>
                {headingText}
            </Heading>
            <Text>{bodyText}</Text>
        </Flex>
    );
}
