import React from "react";
import {
    Heading,
    Text,
    Image,
    Grid,
    GridItem,
    Divider,
    Link,
    Button,
} from "@chakra-ui/react";

export default function TextImageGrid({
    imgOne,
    imgTwo,
    showImgOne,
    bodyTextOne,
    bodyTextTwo,
    bodyTextThree,
}) {
    return (
        <div>
            <Grid
                templateColumns="repeat(2, 1fr)"
                p={{ base: "0em", lg: "3.5em" }}
                bgColor={"#296968"}
            >
                <GridItem
                    colSpan="2"
                    bgColor={"#296968"}
                    color={"white"}
                    p={{ base: "3em", lg: "2em" }}
                >
                    <Heading as="h6">Your Challenge</Heading>
                    <Divider
                        borderBottomWidth="1.2px"
                        opacity="1"
                        borderColor={"#DBDBDB"}
                        pt="1em"
                    />
                </GridItem>

                {showImgOne && (
                    <GridItem
                        colSpan={{ base: "2", lg: "1" }}
                        bgColor={"#296968"}
                        fontColor={"white"}
                        p={{ base: "0", lg: "2em" }}
                    >
                        <Image src={imgOne} />
                    </GridItem>
                )}

                {showImgOne && (
                    <GridItem
                        colSpan={{ base: "2", lg: "1" }}
                        alignSelf="center"
                        bgColor={"#296968"}
                        color={"white"}
                        p={{ base: "2.5em", lg: "2em" }}
                    >
                        <Text fontSize="lg">{bodyTextOne}</Text>
                    </GridItem>
                )}

                {!showImgOne && (
                    <GridItem
                        colSpan={{ base: "2", lg: "2" }}
                        alignSelf="center"
                        bgColor={"#296968"}
                        color={"white"}
                        p={{ base: "2.5em", lg: "2em" }}
                    >
                        <Text fontSize="lg">{bodyTextOne}</Text>
                    </GridItem>
                )}

                <GridItem
                    bgColor={"#ffffff"}
                    colSpan="2"
                    fontWeight="400"
                    p={{ base: "2.5em", lg: "3.2em" }}
                >
                    <Text m="0">{bodyTextTwo}</Text>
                </GridItem>
                <GridItem
                    colSpan={{ base: "2", lg: "1" }}
                    alignSelf="center"
                    bgColor={"#296968"}
                    color={"white"}
                    p={{ base: "2.5em", lg: "2em" }}
                >
                    <Text fontSize="lg">{bodyTextThree}</Text>
                    <Link
                        href="https://calendly.com/lawriefraser/30min"
                        target="_blank"
                    >
                        <Button
                            bg={"#F8641E"}
                            shadow="1px 2px 5px 1px rgba(0, 0, 0, 0.25)"
                            color="white"
                            _hover={{ bg: "rgba(248, 100, 30, 0.7)" }}
                            m="2em 0 0 0"
                        >
                            Book an appointment
                        </Button>
                    </Link>
                </GridItem>
                <GridItem
                    colSpan={{ base: "2", lg: "1" }}
                    bgColor={"#296968"}
                    color={"white"}
                    p={{ base: "0", lg: "2em" }}
                >
                    <Image src={imgTwo} />
                </GridItem>
            </Grid>
        </div>
    );
}
