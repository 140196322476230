import React from "react";
import { Heading, Text, Flex, Image } from "@chakra-ui/react";

export default function CaseStudy({ subheading, imgMobile, imgDesktop }) {
    return (
        <Flex
            bgColor={"#1B4748"}
            basis="100%"
            p={{ base: "0", lg: "7em" }}
            direction="column"
            color="white"
        >
            <Heading
                as="h3"
                fontSize={{ base: "38px", lg: "52px" }}
                textAlign="center"
                p={{ base: "2em 0.3em 0.5em 0.3em", lg: "0 0 0.8em 0" }}
            >
                The BetaPersona Solution
            </Heading>
            <Text
                fontSize="xl"
                fontWeight="bold"
                textAlign="center"
                p={{ base: "2.5em", lg: "1.5em 0 1.5em 0" }}
            >
                {subheading}
            </Text>
            <Image
                src={imgMobile}
                alignSelf="center"
                p={"0em 0em 6em 0em"}
                w="75vw"
                display={{ base: "block", md: "none" }}
            />
            <Image
                src={imgDesktop}
                alignSelf="center"
                p={{ base: "0 1em 7em 1em", lg: "0 1em 0 1em" }}
                w="75vw"
                display={{ base: "none", md: "block" }}
            />
        </Flex>
    );
}
